import React from "react";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import { CopyTradingQuery } from "../../prismic/staticQueries";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import copyTrading_image from "../../images/siteImages/companyProfile/copytrading-banner.png";
import simlifiedtrading from "../../images/siteImages/companyProfile/trading.svg";
import diversification from "../../images/siteImages/companyProfile/diversification.svg";
import riskmanagement from "../../images/siteImages/companyProfile/riskmanagement.svg";
import transparency from "../../images/siteImages/companyProfile/transparency.svg";
// import savetime from "../../images/siteImages/companyProfile/savetime.svg";
// import learnbest from "../../images/siteImages/companyProfile/learnbest.svg";
// import trading24 from "../../images/siteImages/companyProfile/trading24.svg";
// import controlportfolio from "../../images/siteImages/companyProfile/controlportfolio.svg";
// import minimumrisk from "../../images/siteImages/companyProfile/minimumrisk.svg";
import savetime from "../../images/siteImages/home/save-time-2.svg";
import learnbest from "../../images/siteImages/home/learn-best.svg";
import trading24 from "../../images/siteImages/home/all-trading.svg";
import controlportfolio from "../../images/siteImages/home/control-portfolio.svg";
import minimumrisk from "../../images/siteImages/home/minimize-risk.svg";
import banner_image from "../../images/siteImages/companyProfile/copt-trading-breadcrum.png"
import PaymentSlider from "./PaymentSlider";

const whycopytrading_image_icon = [
  simlifiedtrading,
  diversification,
  riskmanagement,
  transparency
];
const benefits_image_icon = [
  savetime,
  learnbest,
  trading24,
  controlportfolio,
  minimumrisk
];

const CopyTrading = () => {
  const language = useSelector((state) => state.language);
  console.log("language", language);

  const CopyTradingData = CopyTradingQuery(language);
  console.log("test");
  // // const { seo_page_title, seo_page_description, seo_page_keywords } =
  // //   PrivacyPolicyData;
  const {
    CopyTrading_heading,
    CopyTrading_body,
    whycopytrading_box,
    whycopytrading_heading,
    benefits_heading,
    benefits_box,

  } = CopyTradingData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="company-profile line-height-lg">
        <Container className="">
          <Row>
            <Col lg={5}>
              <h2 className="pt-5 mt-5 text-bold-md text-black">
                {CopyTrading_heading.text}
              </h2>
              <p className="text-gray mt-5">{CopyTrading_body.text}</p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={6}>
              <figure>
                <div>
                  <img
                    alt="ddd"
                    src={copyTrading_image}
                    className="img img-w-100 pt-5"
                  />
                </div>
              </figure>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pt-5 my-5">
        <Container>
          <h2 className="text-center text-md text-bold-md text-dark mb-5 ">
            {whycopytrading_heading.text}
          </h2>
          <Row>
            {whycopytrading_box.map((reason, reasonInd) => (
              <Col
                lg={3}
                md={6}>
                <div className="square-card mb-4 h-100" key={"reasonInd" + reasonInd}>
                  <div className="d-flex mb-2 align-items-center">
                    <div className="box-icon me-2">
                      <div className="">
                        <img alt="ddd" src={whycopytrading_image_icon[reasonInd]} className="icon-width" style={{ width: "45px" }} />
                      </div>
                    </div>
                    <h6 className="text-white text-bold-md mb-0">
                      {reason.whycopytrading_title.text}
                    </h6>
                  </div>

                  <p className="text-white line-height-lg">
                    {reason.whycopytrading_description.text}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="pt-5 my-5">
        <Container>
          <h2 className="text-center text-md text-bold-md text-dark mb-5 ">
            {benefits_heading.text}
          </h2>
          <Row>
            {benefits_box.map((item, itemInd) => (
              <Col className="mb-5"
                lg={4}
                md={6}>
                <div className="benefits-card mb-4 h-100" key={"reasonInd" + itemInd}>
                  <div className="d-flex mb-2 align-items-center">
                    <div className="box-icon me-2">
                      <div className="icon-square">
                        <img alt="ddd" src={benefits_image_icon[itemInd]} className="icon-width" style={{ width: "45px" }} />
                      </div>
                    </div>
                    <h6 className="text-gray text-bold-md mb-0">
                      {item.benefits_title.text}
                    </h6>
                  </div>

                  <p className="text-gray line-height-lg">
                    {item.benefits_description.text}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(CopyTrading);
